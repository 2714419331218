import $ from 'jquery';
import moment from 'moment';

// function initializeLocationSelector() {
//   $('.location_selector .locations .name').click(function() {
//     $(this).closest('.location_selector').find('.locations .name').removeClass('active');
//     $(this).addClass('active');

//     $(this).closest('.form-group').find('input').val($(this).data('id'));
//   });
// }

function calculatePrice() {
  var price = 0;
  var pax = parseInt($('form input#reservation_pax').val());

  if (pax <= 3) { price = 2990; }
  else if (pax > 3 && pax <= 8) { price = 5500; }
  else { price = 6990; }

  $('.summary .price_service').text(price.toString());

  if ($('.meal-menus .meal-menu.active').length) {
    var menuPrice = $('.meal-menus .meal-menu.active .header .price .price-number').data('price');
    var presentIngredientsCount = $('.meal-menus .meal-menu.active .ingredients input:checked').length;

    price += (menuPrice - (presentIngredientsCount * 5)) * pax;

    $('.summary .price_menu').text((pax * menuPrice).toString());
    $('.summary .ingredients_discount').text((presentIngredientsCount * 5 * pax).toString());
  } else {
    $('.summary .price_menu').text('0');
    $('.summary .ingredients_discount').text('0');
  }

  $('.summary .price_estimate').text(price.toString());
}

$( document ).on('turbolinks:load', function() {
  calculatePrice();

  if ($('#datetimepicker_start').length) {
    $('#datetimepicker_start').datetimepicker({
        locale: 'cs',
        format: 'YYYY-MM-DD HH:mm',
        sideBySide: true,
        inline: true,
        // ignoreReadonly: false,
        // allowInputToggle: true,
        disabledHours: [0, 1, 2, 3, 4, 5, 6, 7],
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'far fa-arrow-up',
            down: 'far fa-arrow-down',
            previous: 'far fa-chevron-left',
            next: 'far fa-chevron-right',
            today: 'far fa-calendar-check-o',
            clear: 'far fa-trash',
            close: 'far fa-times'
        }
      });
    $('#datetimepicker_start').datetimepicker('date', moment($('form #reservation_start_time').val(),"YYYY-MM-DD hh:mm:ss Z"));
    $('#datetimepicker_start').on("change.datetimepicker", function (e) {
      console.log(moment(e.date).format('YYYY-MM-DD HH:mm'));
      $('form #reservation_start_time').val(moment(e.date).format('YYYY-MM-DD HH:mm'));
    });
    $('#datetimepicker_serving').datetimepicker({
        locale: 'cs',
        // format: 'YYYY-MM-DD HH:mm',
        format: 'HH:mm',
        // sideBySide: true,
        inline: true,
        disabledHours: [0, 1, 2, 3, 4, 5, 6, 7],
        icons: {
            time: 'far fa-clock',
            date: 'far fa-calendar',
            up: 'far fa-arrow-up',
            down: 'far fa-arrow-down',
            previous: 'far fa-chevron-left',
            next: 'far fa-chevron-right',
            today: 'far fa-calendar-check-o',
            clear: 'far fa-trash',
            close: 'far fa-times'
        }
      });
    $('#datetimepicker_serving').datetimepicker('date', moment($('form #reservation_serving_time').val(),"YYYY-MM-DD hh:mm:ss Z"));
    $('#datetimepicker_serving').on("change.datetimepicker", function (e) {
      console.log(moment(e.date).format('YYYY-MM-DD HH:mm'));
      $('form #reservation_serving_time').val(moment(e.date).format('YYYY-MM-DD HH:mm'));
    });
  }

  $('.meal-menus .meal-menu .btn').click(function() {
    var mealMenus = $(this).closest('.meal-menus');
    var input = mealMenus.find('input#reservation_menu_id');
    var menu = $(this).closest('.meal-menu');    

    if (menu.hasClass('active')) {
      input.val('');
      menu.removeClass('active');
      menu.find('.btn').text("Zvolit menu");
      menu.find('.ingredients input:checked').prop("checked", false );
      mealMenus.find('.meal-menu').show();
    } else {
      mealMenus.find('.meal-menu.active').removeClass('active');
      mealMenus.find('.meal-menu').hide();
      input.val(menu.data('menu-id'));
      menu.addClass('active');
      menu.show();
      menu.find('.btn').text("Zrušit volbu");
    }
    
    calculatePrice();
  });

  $('form input#reservation_pax').change(function() {
    calculatePrice();
  });

  $('.ingredients .ingredient input').click(function() {
    if ($(this).is(':checked')) {
      $(this).closest('.ingredient').addClass('active');
    } else {
      $(this).closest('.ingredient').removeClass('active');
    }

    var ingredients = $(this).closest('.ingredients');
    var selectedCount = ingredients.find("input:checked").length;
    var priceNumber = $(this).closest('.meal-menu').find('.header .price .price-number');
    var price = priceNumber.data('price');
    price -= selectedCount * 5;
    priceNumber.text(price.toString());

    var selectedIngredients = [];
    ingredients.find("input:checked").each(function() {
      selectedIngredients.push($(this).closest('.ingredient').data('ingredient'));
    });
    $(this).closest('.meal-menus').find('input#reservation_present_ingredients').val(selectedIngredients.join("\n"));

    calculatePrice();
  });
});